@import url('https://fonts.googleapis.com/css2?family=Krub:wght@500;600;700&family=Oswald:wght@500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

* {
    padding: 0;
    box-sizing: border-box;
}
html {
  background: #f6f6f6;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  margin: 0;
}
body {
  margin: 0;
    color: #111111;
}

a {
    text-decoration: none;
}
